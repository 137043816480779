.label {
	cursor: pointer;
	display: flex;
	align-items: center;
	width: fit-content;

	& > div {
		margin-left: .75rem;
	}

	& > input {
		position: relative;
		z-index: -1;
		width: 0;
		opacity: 0;
	}

	& .rect_stroke {
		stroke: var(--color-primary-1);
	}

	& .rect_fill {
		fill: var(--color-primary-1);
	}

	& .check_mark {
		fill: var(--color-black-1);
	}

	&:hover {
		cursor: pointer;

		& .rect_stroke {
			stroke: var(--color-primary-2);
		}
  
		& .rect_fill {
			fill: var(--color-primary-2);
		}

		& .check_mark {
			fill: var(--color-black-1);
		}
	}

	&_disabled {
		& .rect_stroke {
			stroke: var(--color-black-2);
		}
  
		& .rect_fill {
			fill: var(--color-black-2);
		}

		&:hover {
			cursor: default;

			& .rect_stroke {
				stroke: var(--color-black-2);
			}
    
			& .rect_fill {
				fill: var(--color-black-2);
			}
		}
	}

	&_another_disabled {
		&:hover {
			cursor: default;

			& .rect_stroke {
				stroke: var(--color-primary-1);
			}
    
			& .rect_fill {
				fill: var(--color-primary-1);
			}
		}
	}
}
