$cell-size: 1.5rem;
$cell-gap: .125rem;
$activeUser-gap: .75rem;

.container {
	position: sticky;
	z-index: 6;
	left: 0;

	display: flex;
	gap: $cell-gap;
	align-items: flex-end;

	font-size: .75rem;

	background-color: var(--color-bg-blocks);
}

.column {
	position: sticky;

	& > div:not(:last-child) {
		margin-bottom: $cell-gap;
	}
}

.activeUser {
	& > div:nth-child(2){
		margin-bottom: $activeUser-gap;
	}
}

.header {
	position: sticky;
	top: 0;
	left: 0;

	margin-right: -.5rem;
	padding-right: .5rem;

	background-color: var(--color-bg-blocks);
}

.row {
	display: flex;
	gap: $cell-gap;
	height: $cell-size;
	
	&__header {
		position: sticky;
		top: 0;
		left: 0;

		display: flex;
		gap: .25rem;
		align-items: center;
		justify-content: center;

		width: 100%;

		color: var(--color-secondary-3);
		text-transform: uppercase;

		& svg {
			width: fit-content;
			height: .3rem;
		}
	}

	&_clickable {
		cursor: pointer;
	}
}

.cell {
	overflow: hidden;

	height: $cell-size;
	padding: 0 .5rem;

	text-overflow: ellipsis;
	white-space: nowrap;

	background-color: var(--color-bg-disabled);
	border-radius: .1rem;

	&__day {
		width: $cell-size;
		padding: 0;
		text-align: center;
	}

	&__user {
		background-color: var(--color-bg-hover-50);
	}

	&_clickable {
		cursor: pointer;

		&:hover {
			color: var(--color-black-1);
			background-color: var(--color-bg-1)
		}
	}
}

.divider {
	background-color: var(--color-black-2);
	border-radius: 1rem;
}