@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

$border-radius: .75rem;

.menu {
	@include layout.flex-start(column, .25rem, flex-start);

	padding: .5rem;
	background: var(--color-bg-blocks);
	border-radius: $border-radius;
	box-shadow: var(--shadow-default);
}

.item_container {
	@include layout.flex(row, 0.5rem, flex-start);

	cursor: pointer;
	width: 100%;
	padding: .5rem .75rem;
	border-radius: .625rem;

	& > svg {
		fill: var(--color-secondary-2);
	}

	& > span {
		@include typography.font-text-placeholder;
	}

	&:hover {
		cursor: pointer;
		background-color: var(--color-bg-hover-25);
	}

	&:active {
		color: var(--color-primary-1);
		background-color: var(--color-bg-active-bar);

		& > svg {
			fill: var(--color-primary-1);
		}
	}

	&:focus-visible {
		background-color: var(--color-bg-blocks);
		outline: none;
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;

		& > span {
			color: var(--color-black-3);
		}

		& > svg {
			fill: var(--color-black-3);
		}
	}
}
