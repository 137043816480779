@use "src/shared/styles/variables/typography";

.container {
 margin: 0 .75rem 1rem;
}

.heading {
	text-align: center;
}

.user {
	margin-bottom: 1.5rem;

	&__label {
		@include typography.font-field-label;
		
		margin-bottom: .25rem;
		color: var(--color-secondary-3);
	}
}

.input {
	margin-bottom: 2rem;
}

.error {
	margin-bottom: 2rem;
	text-align: center;
	color: var(--error-dark);
}