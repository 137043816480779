@use 'src/shared/styles/layout/layout';

.table {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	&_column {
		display: grid;
		grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr auto;
		column-gap: 85px;
		color: var(--color-secondary-3);
		padding: 0 2rem 0 6rem;
	}

	&_row {
		@include layout.card;
		display: grid;
		grid-template-columns: repeat(6, 1fr) 1.5rem;
		gap: 1rem;
	}
}

.cell_card {
	@include layout.card;
	cursor: pointer;

	&:hover {
		box-shadow: var(--shadow-hover);
	}
}

.sort_wrapper {
	display: flex;
	align-items: center;
	height: 1.5rem;
	
	& svg {
		fill: var(--color-secondary-2);
	}
}

.clickable {
	cursor: pointer;
}