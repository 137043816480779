.success {
  &_container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    margin: 0 2rem;
  }

  &_description {
    text-align: center;
    white-space: pre-wrap;
  }
}

.navigation {
  &_text {
    text-align: center;
  }

  &_link {
    cursor: pointer;
    color: var(--color-primary-1);
  }
}
