@use "src/shared/styles/variables/typography";

@mixin cell {
	&:nth-child(2) {
		width: 40%;
	}

	&:first-child {
		padding-left: 2rem;
	}

	&:last-child {
		padding-right: 2rem;
	}
}

.table_large {
	tbody > tr > td {
		@include cell;
		padding: 1.5rem .5rem;
	}
}

.table_small {
	tbody > tr > td {
		@include cell;
		padding: .5rem;
	}
}

.name {
	font-weight: 600;
}

.position {
	@include typography.font-body-2;
	color: var( --color-secondary-3);
}

.removeButton {
	cursor: pointer;
	fill: var(--color-secondary-2);

	&:hover,
	&:active {
		fill: var(--color-secondary-3);
	}
}
