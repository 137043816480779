@use 'src/shared/styles/variables/typography';

.header {
	display: flex;
	gap: 1rem;

	margin-right: 5.5rem;
	margin-bottom: .5rem;
	margin-left: 5rem;

	& > span {
		@include typography.font-body-2;

		flex: 1 1 26rem;
		color: var(--color-secondary-3);
		text-transform: uppercase;
	}
}

.department_name {
	display: flex;
	gap: 2rem;

	& > span {
		flex: 1 1 15rem;
	}
}

.users_list {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.sort_wrapper {
	display: flex;
	align-items: center;
	height: 1.5rem;
	cursor: pointer;
	
	& svg {
		fill: var(--color-secondary-2);
	}
}