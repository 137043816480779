@use 'src/shared/styles/variables/typography';

.container {
	padding: 2rem;
	background: var(--color-bg-hover-25);
	border: 2px dashed var(--color-secondary-1);
	border-radius: 0.75rem;

	&:hover {
		cursor: pointer;
		background: var(--color-bg-hover-50);
	}
}

.text {
	&_wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		& > svg {
			width: 4rem;
			height: 4rem;
			fill: var(--color-secondary-2);
		}
	}

	&_main {
		margin-bottom: .5rem;
	}

	&_comment {
		@include typography.font-body-1;
		color: var(--color-secondary-3);
	}
}

.buttons {
	display: flex;
	gap: 2rem;
	justify-content: center;
	margin-top: 1rem;
}
