.container {
  &_basic {
    display: inline;
  }

  &_custom {
    display: block;
    width: 100%;
    height: 100%;
  } 
}

.tooltip {
  width: fit-content;

  &_basic {
    padding: .25rem .5rem;

    font-size: 90%;
    color: white;

    background-color: #444;
    border-radius: .25rem;
  }

  &_custom {
    padding: .5rem .75rem;

    font-size: .75rem; 
    line-height: 1rem;
    color: var(--color-black-1);
    white-space: pre-wrap;
  
    background-color: var(--color-secondary-3);
    border-radius: .75rem;
  }
}