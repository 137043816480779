$cell-size: 1.5rem;
$cell-gap: .125rem;
$month-gap: .5rem;

.container {
	position: absolute;
	z-index: 4;

	width: $cell-size;
	height: $cell-size;

	background-color: purple;
	border-radius: .1rem;
	box-shadow: -0.125rem 0 0 0 var(--color-bg-blocks), 0.125rem 0 0 0 var(--color-bg-blocks);
}

.tooltip {
	& > div:not(:last-child) {
		margin-bottom: .5rem;
	}
}