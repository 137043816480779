.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.heading {
	margin-bottom: 2rem;
}

.description {
	margin-bottom: 1.5rem;
	color: var(--color-secondary-3);
	text-align: center
}

.dates {
	margin-bottom: 1.5rem;
}

.comment {
	margin-bottom: 2rem;
}