@use 'src/shared/styles/layout/layout';
@use "src/shared/styles/variables/typography";

$icon-size: 1rem;

.table {
	display: flex;
	flex-direction: column;
	gap: .5rem;

	&_row {
		display: grid;
		gap: 1rem;
		align-items: center;
		// grid-template-columns: 48px repeat(3, 1fr) 24px;
	}

	&_header {
		@include typography.font-body-2;

		color: var(--color-secondary-3);
	}
}

.header_wrapper {
	margin: 0 1.5rem;
}

.cell_card {
	@include layout.card;

	cursor: pointer;

	&:hover {
		box-shadow: var(--shadow-hover);
	}
}

.name {
	display: flex;
	gap: .25rem;
	align-items: center;

	& > svg {
		width: $icon-size;
		min-width: $icon-size;
		height: $icon-size;
		min-height: $icon-size;
	
		fill: var(--warning-dark);
	}
}

.sort_wrapper {
	display: flex;
	align-items: center;
	height: 1.5rem;
	
	& svg {
		fill: var(--color-secondary-2);
	}
}

.clickable {
	cursor: pointer;
}