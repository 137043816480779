$gap: 1.5rem;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fields {
  width: 100%;
  margin-bottom: .375rem;

  & > div:not(:last-child) {
    margin-bottom: $gap;
  }
}

.timeFields {
  display: flex;
  gap: $gap;
}

.buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}
