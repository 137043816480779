.bug_report_modal {
	& > div > div > div:nth-of-type(2) {
		padding: 0 .75rem 1.5rem 1.5rem;
	}
}

.confirm_modal {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
}