@use "src/shared/styles/variables/typography";

.form {
	display: flex;
	flex-direction: column;

	& > h4 {
		@include typography.font-headline-8;

		&:first-of-type {
			margin-bottom: 0.75rem;
		}

		&:last-of-type {
			margin-bottom: 1rem;
		}
	}
}

.fields {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	align-items: center;

	margin-bottom: 1.5rem;
}

.addButton {
	margin-bottom: .5rem;
}

.users {
	overflow-y: auto;
	flex: 1 1 14rem;
	margin:  0 -0.75rem -0.5rem;
	padding: 0 0.75rem 0.5rem;
}

.buttons {
	margin-top: 2rem;
}