@use 'src/shared/styles/layout/layout';
@use 'src/shared/styles/variables/typography';

.table {
	display: flex;
	flex-direction: column;

	overflow-y: auto;
	max-height: 40vh;
	
	&_row {
		display: grid;
		gap: 2rem;
		grid-template-columns: repeat(2, 1fr) 1.5rem;
		margin-bottom: .5rem;

		& > div {
			display: flex;
			align-items: center;
		}

		&:first-child {
			padding: 0 6rem;
		}

		&:last-child {
			margin-bottom: .1rem;
		}
	}

	&_card {
		@include layout.card;
		padding: .5rem 2rem; 
		margin: 0 .1rem .5rem .1rem; 
	}

	&_header {
		@include typography.font-body-2;
		color: var(--color-secondary-2);
	}
}

.sort_wrapper {
	display: flex;
	align-items: center;
	height: 1.5rem;
	
	& svg {
		fill: var(--color-secondary-2);
	}
}

.clickable {
	cursor: pointer;
}