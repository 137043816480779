.heading {
	text-align: center;
}

.info {
	text-align: center;
	margin-bottom: 2rem;
}

.link {
	text-decoration: none;
	color: var(--color-primary-1);
}